.table-pagination {
    .item {
        width: 36px;
        height: 36px;
        // border: 1px solid #75747F;
        color: #75747F;
        letter-spacing: unset;

        &.selected,
        &:hover {
            border: 1px solid #1C1C30;
            color: #1C1C30;
        }
    }
}