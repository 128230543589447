.fixed-notification {
  position: fixed;
  z-index: 9999;
  margin: 16px;
  width: 440px;
}

/* Posicionamiento */
.fixed-notification.top-right {
  top: 0;
  right: 0;
}

.fixed-notification.bottom-right {
  bottom: 0;
  right: 0;
}

.fixed-notification.bottom-left {
  bottom: 0;
  left: 0;
}

.fixed-notification.top-left {
  top: 0;
  left: 0;
}

.fixed-notification-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F6F7;
  padding: 16px;
  border-radius: 2px;
  min-width: 440px;
  max-width: fit-content;
}

.fixed-notification-link {
  color: #1C1C30;
  text-wrap: nowrap;
  text-decoration: underline;
  font-weight: 500;
}

.fixed-notification-close {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-notification-close:hover {
  opacity: 0.7;
}

/* Animación de entrada */
@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fixed-notification {
  animation: slideIn 0.3s ease-out forwards;
}
