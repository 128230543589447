@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?yqfxpk');
  src:  url('./fonts/icomoon.eot?yqfxpk#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?yqfxpk') format('truetype'),
    url('./fonts/icomoon.woff?yqfxpk') format('woff'),
    url('./fonts/icomoon.svg?yqfxpk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-library:before {
  content: "\e001";
}
.icon-icon-home:before {
  content: "\e002";
}
.icon-icon-compliance:before {
  content: "\e003";
}
