.table-v1-3 {
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative; // Para posicionar el spinner relativamente a la tabla
}

table{
    width: 100%;
    position: relative;
}

// Contenedor del spinner para scroll infinito
.infinite-scroll-spinner {
    position: sticky;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 20%);
    width: 100%;
    z-index: 10;

    // Asegurar que el spinner esté centrado
    > div {
        margin: 0 auto;
    }
}

thead {
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 0 inherit, inset 0 -2px 0 inherit;
    padding-top: 5px;
    position: sticky;
    top: 0;
    z-index: 3;
}

.msg-art-not-found{
    display: flex;
    flex: 1 1 auto;
    position: sticky;
    left:0;
    justify-content: center;
    align-items: center;
}

.article-info {
    position: sticky !important;
    left: 0px;
    background-color: #FFFFFF;
    z-index: 2;
    max-width: 200px !important;
    min-width: 200px !important;
}

.striped .article-info {
        background-color: #FBFBFC;
    }