@import '../../../styles/app/common/variables.scss';

.spinner-bcome {
  margin-left: 15px;
  display: inline-block;
  -webkit-mask: url('../../../styles/icons/bcome-spinner.svg') no-repeat 50% 50%;
  mask: url('../../../styles/icons/bcome-spinner.svg') no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.page-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.text-loader {
  color: #aaa;
}

.local-loader {
  height: 200px;
  display: none;
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  // stroke: #ddd;
  stroke: $corporative;
  opacity: 0.8;
  animation: dash $duration ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }

  25% {
    stroke: #de3e35;
  }

  50% {
    stroke: #f7c223;
  }

  75% {
    stroke: #1b9a59;
  }

  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}